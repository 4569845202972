document.addEventListener("turbolinks:load", function() {
  const currentPath = window.location.pathname;

  // contents/newページを開いた時
  if (currentPath === '/contents/new') {
    handleDataAndSendToServer();
  }

  // contents/(id)/editページを開いた時
  const matchResult = currentPath.match(/^\/contents\/(\d+)\/edit$/);
  if (matchResult) {
    handleDataAndSendToServer();
  }

  // mail_magazines/newページを開いた時
  if (currentPath === '/mail_magazines/new') {
    handleDataAndSendToServer();
  }

  // mail_magazines/(id)/editページを開いた時
  const matchMailResult = currentPath.match(/^\/mail_magazines\/(\d+)\/edit$/);
  if (matchMailResult) {
    handleDataAndSendToServer();
  }

  // ロイヤリティのチェックボックスをつけたor外した時
  document.querySelectorAll('.service_checkbox_royalty input[type="checkbox"]').forEach(function (checkbox) {
    checkbox.addEventListener('change', handleDataAndSendToServer);
  });

  // 閲覧履歴カテゴリーのチェックボックスをつけたor外した時
  document.querySelectorAll('.service_checkbox_browse_history_category input[type="checkbox"]').forEach(function (checkbox) {
    checkbox.addEventListener('change', handleDataAndSendToServer);
  });

  // AIコンシェルジュのチェックボックスをつけたor外した時
  document.querySelectorAll('.service_checkbox_ai_concierge input[type="checkbox"]').forEach(function (checkbox) {
    checkbox.addEventListener('change', handleDataAndSendToServer);
  });

  // アンケートフォームのチェックボックスをつけたor外した時
  document.querySelectorAll('.service_checkbox_recruitment_questionnaire input[type="checkbox"]').forEach(function (checkbox) {
    checkbox.addEventListener('change', handleDataAndSendToServer);
  });

  // キーワードのチェックボックスをつけたor外した時
  document.querySelectorAll('.service_checkbox_keyword input[type="checkbox"]').forEach(function (checkbox) {
    checkbox.addEventListener('change', handleDataAndSendToServer);
  });

  //カスタマイズサービスのチェックボックスをつけたor外した時
  document.querySelectorAll('.service_checkbox_customize_service input[type="checkbox"]').forEach(function(checkbox) {
    checkbox.addEventListener('change', handleDataAndSendToServer);
  });

  //予約情報のチェックボックスをつけたor外した時
  document.querySelectorAll('.service_checkbox_access_info input[type="checkbox"]').forEach(function(checkbox) {
    checkbox.addEventListener('change', handleDataAndSendToServer);
  });

  //予約のチェックボックスをつけたor外した時
  document.querySelectorAll('.service_checkbox_booking input[type="checkbox"]').forEach(function(checkbox) {
    checkbox.addEventListener('change', handleDataAndSendToServer);
  });

  // 閲覧履歴URLに文字を入力した時
  document.querySelectorAll('#m_node_type_browse_history_url').forEach(function (element) {
    element.addEventListener('change', function (event) {
      if (event.target.matches('.url-field input.field_name')) {
        handleDataAndSendToServer();
      }
    });
  });

  // お気に入り履歴に文字を入力した時
  document.querySelectorAll('#m_node_type_favorite_history').forEach(function (element) {
    element.addEventListener('change', function (event) {
        if (event.target.matches('.url-field input.field_name')) {
          handleDataAndSendToServer();
        }
    });
  });

  // 閲覧履歴URLのxボタンを押した時
  document.querySelectorAll('#m_node_type_browse_history_url').forEach(function (element) {
    element.addEventListener('click', function (event) {
        if (event.target.matches('.remove-field')) {
          handleDataAndSendToServer();
        }
    });
  });

  // お気に入り履歴のxボタンを押した時
  document.querySelectorAll('#m_node_type_favorite_history').forEach(function (element) {
    element.addEventListener('click', function (event) {
        if (event.target.matches('.remove-field')) {
          handleDataAndSendToServer();
        }
    });
  });

  // コンテンツ&メールマガジンのチェックボックスタイプのサービスのクリアボタンを押した時
  document.querySelectorAll('.clear_checkbox').forEach(function (element) {
    element.addEventListener('click', clearCheckbox);
  });

  // コンテンツ&メールマガジンのチェックボックスを全てfalseに変更する
  function clearCheckbox(e) {
    const clearTargetContainer = e.target.closest('.content-form-block');
    clearTargetContainer
      .querySelectorAll('input[type="checkbox"]')
      .forEach((element) => {
        element.checked = false;
      });
    // 該当人数の取得メソッドを呼び出す
    handleDataAndSendToServer();
  }

  // node_checkbox のチェックボックスが変更されたときのイベントリスナーを追加
  var nodeCheckboxes = document.querySelectorAll('.node_checkbox');
  for (var i = 0; i < nodeCheckboxes.length; i++) {
    nodeCheckboxes[i].addEventListener('change', handleCheckboxChange);
  }

  function handleCheckboxChange(e) {
    var nodeGroup = e.target.closest('.node_group');
    updateGroupCheckboxState(nodeGroup);
  }

  // ノードグループ内のチェックボックスの状態をみてグループ名のチェックボックスの状態を変更する
  function updateGroupCheckboxState(nodeGroup) {
    var allCheckboxes = nodeGroup.querySelectorAll('.node_checkbox');
    var allChecked = true;

    for (var j = 0; j < allCheckboxes.length; j++) {
      if (!allCheckboxes[j].checked) {
        allChecked = false;
        break;
      }
    }

    var btnAllCheck = nodeGroup.querySelector('.btn-all-check');
    if (btnAllCheck) {
      btnAllCheck.checked = allChecked;
    }
  }

  // コンテンツ&メールマガジンのチェックボックスタイプのサービスのグループの全てチェックボタンを押した時
  document.querySelectorAll('.btn-all-check').forEach(function (element) {
    element.addEventListener('click', allTrueCheckbox);
  });

  // ページ読み込み時にグループのチェックボックスの状態を更新する
  document.querySelectorAll('.btn-all-check').forEach(function (element) {
    var nodeGroup = element.closest('.node_group');
    updateGroupCheckboxState(nodeGroup)
  });

  // コンテンツ&メールマガジンのチェックボックスを全てfalseに変更する
  function allTrueCheckbox(e) {
    const clearTargetContainer = e.target.closest('.node_group');

    let all = clearTargetContainer.querySelectorAll('input[type="checkbox"].node_checkbox').length;
    let count = clearTargetContainer.querySelectorAll('input[type="checkbox"].node_checkbox:checked').length;
    let checked = true;
    if (all == count) {
      checked = false;
    }

    clearTargetContainer
      .querySelectorAll('input[type="checkbox"]')
      .forEach((element) => {
        element.checked = checked;
      });
  }

  // コンテンツのURLタイプのサービスのクリアボタンを押した時
  document.querySelectorAll('.clear_urlform').forEach(function (element) {
    element.addEventListener('click', clearUrlform);
  });

  // コンテンツのURLのフォームを非表示&nameの値を空に変更する
  function clearUrlform(e) {
    const clearTargetContainer = e.target.closest('.content-form-block');
    clearTargetContainer
      .querySelectorAll('.url-field')
      .forEach(function (urlField) {
        urlField.style.display = 'none';
        urlField.querySelector('.field_name').value = '';
      });
    // 該当人数の取得メソッドを呼び出す
    handleDataAndSendToServer();
  }

  // メールマガジンのURLタイプのサービスのクリアボタンを押した時
  document.querySelectorAll('.clear_urlform_mail').forEach(function (element) {
    element.addEventListener('click', clearUrlformMail);
  });

  // メールマガジンの送信方法を切り替えたとき
  document.querySelectorAll('#designe_radio_send_method input').forEach((element) => {
    element.addEventListener('click', handleDataAndSendToServer);
  });

  // メールマガジンのURLのフォームを非表示&nameの値を空に変更する
  function clearUrlformMail(e) {
    const clearTargetContainer = e.target.closest('.content-form-block');
    clearTargetContainer
      .querySelectorAll('.url-field')
      .forEach(function (urlField) {
        urlField.style.display = 'none';
        urlField.querySelector('.field_name').value = '';
      });
    // 該当人数の取得メソッドを呼び出す
    handleDataAndSendToServer();
  }

  function handleDataAndSendToServer(event) {
    // 現在のページのURLからページの種類を取得
    const pageType = getPageTypeFromURL(window.location.pathname);

    // コンテンツ管理画面で選択されているチェックボックスのm_node_type_idと、入力されているURLの文字列を取得
    let data = {};
    data = NodeIdsPushToArray(data, 'royalty', '.service_checkbox_royalty input[type="checkbox"].node_checkbox:checked');
    data = ValuePushToArray(data, 'gamen_browse_history_url', '#m_node_type_browse_history_url .url-field input.field_name');
    data = NodeIdsPushToArray(data, 'browse_history_category', '.service_checkbox_browse_history_category input[type="checkbox"].node_checkbox:checked');
    data = ValuePushToArray(data, 'gamen_favorite_history', '#m_node_type_favorite_history .url-field input.field_name');
    data = NodeIdsPushToArray(data, 'ai_concierge', '.service_checkbox_ai_concierge input[type="checkbox"].node_checkbox:checked');
    data = NodeIdsPushToArray(data, 'recruitment_questionnaire', '.service_checkbox_recruitment_questionnaire input[type="checkbox"].node_checkbox:checked');
    data = NodeIdsPushToArray(data, 'keyword', '.service_checkbox_keyword input[type="checkbox"].node_checkbox:checked');
    data = NodeIdsPushToArray(data, 'customize_service', '.service_checkbox_customize_service input[type="checkbox"].node_checkbox:checked');
    data = NodeIdsPushToArray(data, 'access_info', '.service_checkbox_access_info input[type="checkbox"].node_checkbox:checked');
    data = ValuePushToArray(data, 'booking', '.service_checkbox_booking input[name="mail_magazine[m_booking_item_ids][]"].node_checkbox:checked');

    // csrfトークンを取得
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

    // 送るjsonデータを作成
    const payload = {
      page: pageType,
      data: data
    };

    // サーバーにデータを送信
    sendDataToServer(payload, csrfToken);
  }

  function NodeIdsPushToArray(data, key, selector) {
    data[key] = [];

    for (const checkbox of document.querySelectorAll(selector)) {
      const label = checkbox.closest('label');
      const mNodeTypeIdValue = label.querySelector('.m_node_type_id-hidden').value;
      data[key].push(mNodeTypeIdValue);
    }
    return data;
  }

  function ValuePushToArray(data, key, selector) {
    data[key] = [];

    for (const val of document.querySelectorAll(selector)) {
      const inputValue = val.value;

      if (inputValue !== null && inputValue.trim() !== "") {
        data[key].push(inputValue);
      }
    }
    return data;
  }

  // ページの種類を取得。URLにcontentsが含まれているかmail_magazinesが含まれているかで判断
  function getPageTypeFromURL(pathname) {
    if (pathname.includes('contents')) {
      return 'contents';
    } else if (pathname.includes('mail_magazines')) {
      // どちらのラジオボタンが選択されているかを確認
      const sendMethodDateRadio = document.getElementById('mail_magazine_send_method_true');
      const sendMethodTimingRadio = document.getElementById('mail_magazine_send_method_false');

      if (sendMethodDateRadio.checked) {
        return 'mail_magazines_send_method_true';
      } else if (sendMethodTimingRadio.checked) {
        return 'mail_magazines_send_method_false';
      }
    } else {
      return 'unknown';
    }
  }

  function sendDataToServer(data, csrfToken) {
    // fetchメソッドを使用してデータを送信する
    fetch("/service_match_user_count", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // サーバーから人数の数字が返るのでid="userCount"のspanタグのインナーテキストを書き換える
        const userCountValue = responseData.user_count;
        const userCountSpan = document.getElementById("userCount");
        userCountSpan.innerText = userCountValue;
      })
      .catch((error) => {
        console.error("エラー:", error);
      });
  }
});
